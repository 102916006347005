
import React from 'react';
import ReactDOM from 'react-dom';
import Header from './app';
import '../node_modules/@amzn/awsui-components-react/index.min.css';
import './app/navbar.scss';


ReactDOM.render(
<div className="awsui">
      <Header /></div>,
  document.getElementById('root'),
);