import {   Table,
  TableFiltering,
  TablePagination,
  TableSorting,
  TablePreferences,
  Select,
  Flash,
  TableContentSelector,
  Button,TablePageSizeSelector,
  TableWrapLines,
  TableSelection, } from "@amzn/awsui-components-react";
import React from 'react';
import { columnDefinitions, FinsysRepoApi} from './finsys_repo_api.jsx';
import { getAuthConfig } from './configs.jsx';
import { ensureAuthenticated } from './auth_handler.jsx';

import AWS from 'aws-sdk';

let features =
[
  "filtering",
  "pagination",
  "selection",
  "sorting"
]

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function setCookie(cname, cvalue) {
  var d = new Date();
  d.setTime(d.getTime() + (24*60*60*1000));
  var expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

class Navbar extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return <div className='awsui'>
      <header>
        <ul className="navbar">
          <li className="title"><a href="/"><h2>
            {this.props.overrides ? "Territory to Legal Entity mapping" : "Override Territory to Legal Entity mapping" }</h2></a></li>
          <li className="ml-auto"/>
          <li className="user-guide">
            <a href="https://w.amazon.com/bin/view/AIV/GlobalContractTracker/UserGuide/">User Guide</a>
          </li>
          <li className="feature-request">
            <a href="https://issues.amazon.com/issues/create?assignedFolder=fa1505b8-ae2d-49a5-bc78-e09ba1e35805">Feature Request</a>
          </li>
          <li className="ticket">
            <a href="https://t.corp.amazon.com/create/options?category=Digital+Video&type=Global+Contract+Tracker&item=General&group=DV+Concordia">Support/Bug Ticket</a>
          </li>
        </ul>
      </header>
    </div>
  }
}

function get(n) {
    var half = window.location.href.split(n + '=')[1];
    let value = half !== undefined ? decodeURIComponent(half.split('&')[0]) : null;
    if(value) {
      return value;
    }
    return getCookie(n);
}

class Header extends React.Component {
    constructor(props){
      super(props);
      this.state = {
          items: [],
          edit: false,
          chosen_territory: "",
          errorMsg: "",
          default_mapping: {},
          selectableEntities: [],
          default_territories: [],
          mapping: {},
          selected_value: {},
          showAll: false,
          id: get('id'),
          businessLine: get('businessLine')
      };
      setCookie('id', get('id'))
      setCookie('businessLine', get('businessLine'))
      this.retrieveDefaultMapping = this.retrieveDefaultMapping.bind(this)
      this.retrieveMapping = this.retrieveMapping.bind(this)
      this.onMappingRetrievalSuccess = this.onMappingRetrievalSuccess.bind(this)
      this.onDefaultMappingRetrievalSuccess = this.onDefaultMappingRetrievalSuccess.bind(this)
      this.getModel = this.getModel.bind(this)
      this.getModelSuccess = this.getModelSuccess.bind(this)
      this.getFail = this.getFail.bind(this)
      this.editButtonClick = this.editButtonClick.bind(this)
      this.changeOption = this.changeOption.bind(this)
      this.resetToDefaults = this.resetToDefaults.bind(this)
      this.saveMapping = this.saveMapping.bind(this)
      this.onMappingSaveSuccess = this.onMappingSaveSuccess.bind(this)
      this.addTerritory = this.addTerritory.bind(this)
      this.changeTerritory = this.changeTerritory.bind(this)
      this.prepareWholeMapping = this.prepareWholeMapping.bind(this)
    }

    componentDidMount() {
      ensureAuthenticated(getAuthConfig().read_only, (res) => this.getModel())
    }
    retrieveDefaultMapping() {
      ensureAuthenticated(getAuthConfig().read_only, (token) => FinsysRepoApi.getDefaultMapping(token, this.onDefaultMappingRetrievalSuccess, this.getFail))
    }

    prepareWholeMapping() {
      let draft_values = this.state.mapping
      let items = []
      for (const [key, value] of Object.entries(draft_values)) {
        items.push({
          "territory": key,
          "currentle": value,
          "defaultle": this.state.default_mapping[key],
          "default": value === this.state.default_mapping[key] ? "Yes" : "No"
        })
      }
      for (const [key, value] of Object.entries(this.state.default_mapping)) {
        if(!(key in draft_values)) {
          items.push({
            "territory": key,
            "currentle": value,
            "defaultle": this.state.default_mapping[key],
            "default": value === this.state.default_mapping[key] ? "Yes" : "No"
          })
        }
      }
      this.setState({items: items,
        draft_values: draft_values});
    }

    retrieveMapping(key) {
        ensureAuthenticated(getAuthConfig().read_only, (token) => FinsysRepoApi.getMapping(token, this.state.id, this.onMappingRetrievalSuccess, this.getFail))
    }

    changeTerritory(event) {
      let territory = event.detail.selectedOption.id
      this.setState({selected_value: territory});
    }

    addTerritory() {
      let draft_values = this.state.draft_values
      draft_values[this.state.selected_value] = this.state.default_mapping[this.state.selected_value]
      let items = []
      for (const [key, value] of Object.entries(draft_values)) {
        items.push({
          "territory": key,
          "currentle": value,
          "defaultle": this.state.default_mapping[key],
          "default": value === this.state.default_mapping[key] ? "Yes" : "No"
        })
      }
      this.setState({items: items,
                     draft_values: draft_values});
    }

    onMappingSaveSuccess(key) {
      this.editButtonClick();
      this.getModel();
    }

    changeOption(territory, event) {
      let draft_values = this.state.draft_values
      draft_values[territory] = event.target.value
      this.setState({draft_values: draft_values});
    }

    getModel() {
      FinsysRepoApi.getModel(this.getModelSuccess, this.getFail)
    }

    resetToDefaults() {
      let draft_values = this.state.draft_values
      for (const [key, value] of Object.entries(this.state.draft_values)) {
        draft_values[key] = this.state.default_mapping[key]
      }
      this.setState({draft_values: draft_values});
    }

    editButtonClick() {
      let draft_values = {}
      for (const [key, value] of Object.entries(this.state.mapping)) {
        draft_values[key] = value
      }
      if(this.state.edit) {
        this.getModel();
      }
      this.state.edit = !this.state.edit
      this.state.draft_values = draft_values
      this.setState({edit: this.state.edit, draft_values: this.state.draft_values});
      if(this.state.edit) {
        let authConfig = getAuthConfig()
        ensureAuthenticated(authConfig.all_access)
      }
    }

    getModelSuccess(model) {
      let selectableEntities = []

      for (const value of model.enumerations.legal_entity) {
        selectableEntities.push({"id": value.replace(" [DEPRECATED]", ""), "label": value})
      }
      console.log(selectableEntities)
      this.setState({default_territories: model.enumerations.territories, selectableEntities: selectableEntities}, () => this.retrieveDefaultMapping());
    }

    getFail(res) {
      this.state.errorMsg = res
      this.setState({errorMsg: this.state.errorMsg});
    }

    onMappingRetrievalSuccess(res) {
      let items = []
      for (const [key, value] of Object.entries(res.mapping)) {
        items.push({
          "territory": key,
          "currentle": value,
          "defaultle": this.state.default_mapping[key],
          "default": value === this.state.default_mapping[key] ? "Yes" : "No"
        })
      }
      this.setState({items: items,
                     mapping: res.mapping});
    }

    onDefaultMappingRetrievalSuccess(res) {
      this.setState({default_mapping: res.mapping}, () => this.retrieveMapping(get('id')));
    }

    saveMapping() {
        ensureAuthenticated(getAuthConfig().all_access, (token) => FinsysRepoApi.saveMapping(token, this.state.id, this.state.draft_values, this.onMappingSaveSuccess, this.getFail))
    }

    render() {
      let columnDefinitions =
      [
        {
          id: "territory",
          header: "Territory",
          label: sortState => {
            const columnIsSorted = sortState.sortingColumn === 'variable';
            const ascending = !sortState.sortingDescending;
            return `Variable territory, ${
              columnIsSorted
                ? `sorted ${ascending ? 'ascending' : 'descending'}`
                : 'not sorted'
            }.`;
          },
          cell: item => item.territory,
          minWidth: '140px'
        },
        {
          id: "currentle",
          header: "Current legal entity",
          label: sortState => {
            const columnIsSorted = sortState.sortingColumn === 'currentle';
            const ascending = !sortState.sortingDescending;
            return `Text currentle, ${
              columnIsSorted
                ? `sorted ${ascending ? 'ascending' : 'descending'}`
                : 'not sorted'
            }.`;
          },
          cell: item => this.state.edit ? (<span style={{"zIndex": -1}} >
          <select value ={this.state.draft_values[item.territory]} onChange={(event) => this.changeOption(item.territory, event)}>
          {this.state.selectableEntities.map(option => (
            option.label.indexOf("DEPRECATED") < 0 ? <option id ={option.id} key={option.id} label={option.id} value={option.id}>
              {option.label}
            </option> : 
            <option id ={option.id} key={option.id} label={option.id} value={option.id} disabled>
              {option.label}
            </option>
          ))}
          </select> </span>) : item.currentle,
          minWidth: '110px'
        },
        {
          id: "defaultle",
          header: "Default legal entity",
          cell: item => item.defaultle,
          minWidth: '75px'
        },
        {
          id: "default",
          header: "Default selected",
          cell: item => item.default,
          minWidth: '170px'
        }
      ]
      let menuItemStyle = { float: 'left', paddingRight: '10px' };
      let territoryOptions = []
      for (const territory of this.state.default_territories) {
        territoryOptions.push({
          "id": territory,
          "label": territory
        })
      }

      return <div className="awsui-util-container">
        <Navbar overrides={this.state.showAll}/>
        <span> Title list id: {this.state.id} </span>
        <span> Business line: {this.state.businessLine} </span>
        <Table loadingText="Loading resources" columnDefinitions={columnDefinitions} items={this.state.items} wrapLines={false} features={features} header={<div>

            {this.state.edit ? <div style={{maxWidth: 131}}>
            <Select options={territoryOptions} filteringType="auto"
            onChange={this.changeTerritory} selectedLabel="Selected"></Select>
            <Button onClick={this.addTerritory} text="Add territory" />
            </div>
            : ""}

            </div>} empty={
            <div className="awsui-util-t-c">
                <div className="awsui-util-pt-s awsui-util-mb-xs">
                    <b>
            No resources
            </b>
                </div>
                <p className="awsui-util-mb-s">
                    No resources to display.
                </p>
                <div className="awsui-util-mb-m">
                </div>
            </div>} noMatch={
            <div className="awsui-util-t-c">
                <div className="awsui-util-pt-s awsui-util-mb-xs">
                    <b>
            No matches
            </b>
                </div>
                <p className="awsui-util-mb-s">
                    We can’t find a match.
                </p>
                <div className="awsui-util-mb-m">
                    <Button>
                        Clear filter
                    </Button>
                </div>
            </div>} >
            <TableFiltering filteringPlaceholder="Find territory to legal entity override"></TableFiltering>
            <TablePagination pageSize={10}></TablePagination>
            <TableSorting sortingColumn="variable"></TableSorting>
            <TableSelection trackBy="name"></TableSelection>
            <TablePreferences title="Preferences" confirmLabel="Confirm" cancelLabel="Cancel">
                <TablePageSizeSelector title="Page size" options={[ { value: 5, label: "5 items" }, { value: 10, label: "10 items" }, { value: 25, label: "25 items"}, { value: 50, label: "50 items"}, { value: 100, label: "100 items"}] }></TablePageSizeSelector>
                <TableWrapLines label="Wrap lines" description="Enable to wrap table cell content, disable to truncate text."></TableWrapLines>
                <TableContentSelector title="Select visible columns"></TableContentSelector>
            </TablePreferences>
        </Table>
        {(this.state.edit || this.state.showAll) ? "" : <Button variant="primary" onClick={this.editButtonClick} text="Edit" /> }
        {this.state.edit ? <Button onClick={this.editButtonClick} text="Cancel" /> : "" }
        {this.state.edit ? <Button onClick={this.resetToDefaults} text="Reset to defaults" /> : ""}
        {this.state.edit ? <Button onClick={this.saveMapping} text="Save" /> : ""}
        {this.state.errorMsg ? <Flash type="error" dismissible={true}>
            {this.state.errorMsg}
          </Flash>: ""}

      </div>;
    }
}

export default Header;