import { getAuthConfig } from './configs.jsx';
import { getStageInfo } from "./stage";


let columnDefinitions =
[
  {
    id: "territory",
    header: "Territory",
    label: sortState => {
      const columnIsSorted = sortState.sortingColumn === 'variable';
      const ascending = !sortState.sortingDescending;
      return `Variable territory, ${
        columnIsSorted
          ? `sorted ${ascending ? 'ascending' : 'descending'}`
          : 'not sorted'
      }.`;
    },
    cell: item => item.territory,
    minWidth: '140px'
  },
  {
    id: "currentle",
    header: "Current legal entity",
    label: sortState => {
      const columnIsSorted = sortState.sortingColumn === 'currentle';
      const ascending = !sortState.sortingDescending;
      return `Text currentle, ${
        columnIsSorted
          ? `sorted ${ascending ? 'ascending' : 'descending'}`
          : 'not sorted'
      }.`;
    },
    cell: item => item.currentle,
    minWidth: '110px'
  },
  {
    id: "defaultle",
    header: "Default legal entity",
    cell: item => item.defaultle,
    minWidth: '75px'
  },
  {
    id: "default",
    header: "Default selected",
    cell: item => item.default,
    minWidth: '170px'
  }
]

let model =
{
  "enumerations": {
      "territories": [
        "US",
        "GB",
        "DE",
        "JP",
        "IN",
        "AT",
        "BE",
        "DK",
        "FI",
        "GR",
        "IE",
        "FR",
        "IT",
        "NL",
        "NO",
        "PT",
        "ES",
        "SE",
        "CH",
        "CA",
        "AR",
        "BS",
        "BB",
        "BO",
        "BR",
        "CL",
        "CO",
        "CR",
        "DO",
        "EC",
        "SV",
        "GT",
        "HN",
        "JM",
        "MX",
        "NI",
        "PA",
        "PY",
        "PE",
        "PR",
        "TT",
        "UY",
        "VE",
        "AU",
        "CN",
        "HK",
        "ID",
        "MY",
        "NZ",
        "PH",
        "SG",
        "KR",
        "TW",
        "TH",
        "VN",
        "BG",
        "CZ",
        "EE",
        "HU",
        "LV",
        "LT",
        "PL",
        "RO",
        "RU",
        "SK",
        "BH",
        "EG",
        "IQ",
        "IL",
        "JO",
        "KW",
        "LB",
        "OM",
        "PS",
        "QA",
        "SA",
        "ZA",
        "SY",
        "TR",
        "AE",
        "YE"
  ],
    "legal_entity": [
      "Amazon.com Sales, Inc. (JP)",
      "ASSPL",
      "ADUK (DE)",
      "AMEU (DE)",
      "Amazon.com Services, Inc.",
      "Amazon Digital Services, Inc [DEPRECATED]",
      "ADUK (UK)",
      "AMEU (UK)"
    ]
  }
}
let defaultMapping =
{
  "legal_entity_mapping": {
    "US": "Amazon.com Services LLC",
    "GB": "ADUK (UK)",
    "FR": "AMFR"
  }
}

let payload =
{
  "legal_entity_mapping": {
    "US": "Amazon.com Services LLC",
    "GB": "ADUK (UK)"
  }
}

async function makeCall(token, onSuccess, onFailure, address, method, body) {
    try {
        let options = {method: method};
        let headers = new Headers()
        headers.append('Content-Type','application/json');
        headers.append('access-control-allow-origin',"*");
        headers.append('access-control-allow-method',"POST, GET, OPTIONS, PUT, DELETE");
        headers.append('access-control-allow-headers',"Content-Type");
        headers.append('access-control-request-headers',"X-Requested-With, accept, content-type");
        headers.append('Authorization', token);
        options = {...options, headers : headers};
        fetch('https://{apiId}.execute-api.us-east-1.amazonaws.com/{stage}/'.replace('{stage}', getStageInfo().subdomain).replace('{apiId}', getAuthConfig().apiId) +address,options)
            .then(async response => {
              response = await response.json()
              onSuccess(response)
            })
    } catch(e) {
        return e;
    }
}

class FinsysRepoApi {

  static getDefaultMapping(token, onSuccess, onFailure) {
    console.log(token)
    makeCall(token, onSuccess, onFailure, 'default_ter_to_le', 'GET')
  }

  static getMapping(token, id, onSuccess, onFailure) {
    // TODO: call GetContractMappingRequest in FinsysRepo to get it
    let address =  'ter_to_le?titleListId={titleListId}&titleListRevision=1'.replace('{titleListId}', id)
    makeCall(token, onSuccess, onFailure, address, 'GET');
  }

  static getModel(onSuccess, onFailure) {
    // TODO: maybe in the future this function will call some endpoint of FinsysRepo
    // to get a list of available legal entities and territories. But endpoint like that
    // does not exists yet so this will remain hardcoded for now in the UI in the variable 'model'
    onSuccess(model);
  }

  static saveMapping(token, id, mapping, onSuccess, onFailure) {

    console.log('saving')
    let address =  'internal_revision/{titleListId}/1'.replace('{titleListId}', id)
    let commit =  'internal_revision/{titleListId}/'.replace('{titleListId}', id)
    let update =  'title_list/{titleListId}?newRevision={newRevision}&lastRevision=1&payload='.replace('{titleListId}', id) +  encodeURIComponent(JSON.stringify({legal_entity_mapping: mapping}))
    let commitChangeCallback = (res) => makeCall(token, onSuccess, onFailure, commit+res.newRevision, 'PUT')
    let makeChangeCall = (res) => makeCall(token,
                        () => commitChangeCallback(res),
                        onFailure,
                        update.replace('{newRevision}', res.newRevision), 'PUT', mapping)
    let requestCommitToken = () => makeCall(token,
      makeChangeCall,
      onFailure, address, 'POST');
    requestCommitToken();
  }
}

export { columnDefinitions, FinsysRepoApi};
