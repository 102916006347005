import { getStageInfo } from "./stage";

const AUTH_CONFIGS = {
  dev:{
    'apiId': 'bawmkskred',
     all_access: {
      "AppWebDomain": "beta-acquire-extensions-v2.auth.us-east-1.amazoncognito.com",
      "ClientId": "7ncj694an43fp724v079i10gf6",
      "RedirectUriSignIn": "https://dko754uti4ame.cloudfront.net",
      "RedirectUriSignOut": "https://dko754uti4ame.cloudfront.net",
      "TokenScopesArray": ["openid"],
      "UserPoolId": "us-east-1_95oWircqw"
    },
     read_only : {
      "AppWebDomain": "beta-acquire-extensions-ro-v2.auth.us-east-1.amazoncognito.com",
      "ClientId": "17747aufe1lkfgsqkh3dimiqh9",
      "RedirectUriSignIn": "https://dko754uti4ame.cloudfront.net",
      "RedirectUriSignOut": "https://dko754uti4ame.cloudfront.net",
      "TokenScopesArray": ["openid"],
      "UserPoolId": "us-east-1_Aa7hqF4Yb"
    }
  },
  beta:{
    'apiId': 'bawmkskred',
     all_access: {
      "AppWebDomain": "beta-acquire-extensions-v2.auth.us-east-1.amazoncognito.com",
      "ClientId": "7ncj694an43fp724v079i10gf6",
      "RedirectUriSignIn": "https://beta.acquire-extension.video.amazon.dev/",
      "RedirectUriSignOut": "https://beta.acquire-extension.video.amazon.dev/",
      "TokenScopesArray": ["openid"],
      "UserPoolId": "us-east-1_95oWircqw"
    },
     read_only : {
      "AppWebDomain": "beta-acquire-extensions-ro-v2.auth.us-east-1.amazoncognito.com",
      "ClientId": "17747aufe1lkfgsqkh3dimiqh9",
      "RedirectUriSignIn": "https://beta.acquire-extension.video.amazon.dev/",
      "RedirectUriSignOut": "https://beta.acquire-extension.video.amazon.dev/",
      "TokenScopesArray": ["openid"],
      "UserPoolId": "us-east-1_Aa7hqF4Yb"
    }
  },
  gamma:{
    'apiId': '2gue5jjzo3',
    all_access : {
      "AppWebDomain": "gamma-acquire-extensions-all.auth.us-east-1.amazoncognito.com",
      "ClientId": "129fdsqk7cenlqcl1bmmsrdnip",
      "RedirectUriSignIn":  "https://gamma.acquire-extension.video.amazon.dev",
      "RedirectUriSignOut": "https://gamma.acquire-extension.video.amazon.dev",
      "TokenScopesArray": ["openid"],
      "UserPoolId": "us-east-1_bXesXPOOI"
    },
    read_only : {
      "AppWebDomain": "gamma-acquire-extensions-ro.auth.us-east-1.amazoncognito.com",
      "ClientId": "52sinopr0rc1loudrdvr3gno8i",
      "RedirectUriSignIn":  "https://gamma.acquire-extension.video.amazon.dev",
      "RedirectUriSignOut": "https://gamma.acquire-extension.video.amazon.dev",
      "TokenScopesArray": ["openid"],
      "UserPoolId": "us-east-1_jjYZ8opBP"
    }
  },
  prod:{
    'apiId':'m00p1be2u9',
    all_access : {
      "AppWebDomain": "prod-acquire-extension-all.auth.us-east-1.amazoncognito.com",
      "ClientId": "6f63slu70a24ljbk6t3bjueom8",
      "RedirectUriSignIn":  "https://prod.acquire-extension.video.amazon.dev",
      "RedirectUriSignOut": "https://prod.acquire-extension.video.amazon.dev",
      "TokenScopesArray": ["openid"],
      "UserPoolId": "us-east-1_V5ZKxeuf8"
    },
    read_only : {
      "AppWebDomain": "prod-acquire-extensions-ro.auth.us-east-1.amazoncognito.com",
      "ClientId": "3tfpu10eiu129kn7nlilidv543",
      "RedirectUriSignIn":  "https://prod.acquire-extension.video.amazon.dev",
      "RedirectUriSignOut": "https://prod.acquire-extension.video.amazon.dev",
      "TokenScopesArray": ["openid"],
      "UserPoolId": "us-east-1_8QQHoMGL2"
    }
  }
};

export function getAuthConfig() {
  const stage = getStageInfo().stage + (getStageInfo().subdomain ? "" : "root");
  return AUTH_CONFIGS[stage];
}