import { CognitoAuth } from 'amazon-cognito-auth-js';
import "regenerator-runtime/runtime";

function setupAWSCredentials(auth) {
    const session = auth.getSignInUserSession();
    // TODO: use this to call API gateway in FinsysRepoApi
    const token = session.getIdToken().getJwtToken();
    const userPoolId = auth.userPoolId;
    const region = 'us-east-1';
    const providerName = `cognito-idp.${region}.amazonaws.com/${userPoolId}`;
    const Logins = {
        [providerName] : token
    };
}

function getAuth(authConfig) {
    const auth = new CognitoAuth(authConfig);
    // Make sure you turn on OAuth2 Authorization Code Grant flow
    auth.useCodeGrantFlow();
    return auth;
}

function removeParamsFromLocation() {
    // Replace the href because the Cognito passes the OAuth2 grant code in the query string
    // And the grant code is not reusable
    if (window.location.href.indexOf("id=") != -1) {
        var newURL = location.href.split("?")[0];
        window.history.replaceState('object', document.title, newURL);
    }
}

// This code removes the "?code=..." from the URL. It is because the grant code is not reusable. Sometimes
// the SDK will report weird messages because of using old grant code.
function removeQueryFromLocation() {
    // Replace the href because the Cognito passes the OAuth2 grant code in the query string
    // And the grant code is not reusable
    if (window.history.length > 0) {
        const newHref = window.location.href.split('?')[0];
        window.history.replaceState(undefined, 'Turner', newHref);
    }
}

function setupUserName(auth) {
    window.USERNAME = auth.username.split("_")[1];
}

/**
 * Ensures the user is authenticated.
 * If authenticated, return auth object. Otherwise force authentication.
 * Auth object will be used to call API gateway
 */
export function ensureAuthenticated(authConfig, successCallback) {
    return new Promise((resolve, reject) => {
        try {
            removeParamsFromLocation();
            const auth = getAuth(authConfig);
            // Register callback functions
            auth.userhandler = {
                onFailure: (err) => {
                    removeQueryFromLocation();
                    reject(err);
                },
                onSuccess: (result) => {
                    removeQueryFromLocation();
                    setupAWSCredentials(auth);
                    setupUserName(auth);
                    resolve(auth);
                    if(successCallback) {
                        successCallback(auth.signInUserSession.idToken.jwtToken)
                    }
                },
            }

            const href = window.location.href;
            let session = auth.getSignInUserSession();
            console.log('Session valid ', session.isValid())
            if (session.isValid()) {
                setupAWSCredentials(auth);
                setupUserName(auth);
                resolve(auth);
                if(successCallback) {
                    successCallback(auth.signInUserSession.idToken.jwtToken)
                }
            } else if (href.indexOf('?') > 0) {
                // This is required because Cognito needs to get the authentication result from the query string
                // The parsing is done asynchronously, and the result will be passed to the userHandler.
                // Once the result is parsed, onSuccess userhandler will resolve the promise.
                auth.parseCognitoWebResponse(href);
            } else {
                // Cognito SDK will handle session refresh / authentication.
                auth.clearCachedTokensScopes();
                session = auth.getSession();
            }
        } catch(e) {
            return e;
        }
    });
}