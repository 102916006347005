
export const getStageInfo = function() {
    const host = window.location.host;
    if (host.includes("localhost")){
        return {stage: "dev", subdomain: "beta"};
    }
    if (host.includes("beta") || host.includes("localhost")){
        return {stage: "beta", subdomain: "beta"};
    }
    else if (host.includes("gamma")) {
        return {stage: "gamma", subdomain: "gamma"};
    }
    else if (host.includes("prod")) {
        return {stage: "prod", subdomain: "prod"};
    }
    return {stage: "dev", subdomain: "beta"};
};